import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'
import Button from '~components/Button'

import './index.scss'

const GetInTouch = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-20%",
    threshold: 0
  })

  const buttonData = {
    tag: 'link',
    text: data.button_text,
    url: data.button_url
  }

  return (
    <section className="get-in-touch" ref={componentRef}>
      <div className="get-in-touch__container">
        <div className="get-in-touch__content">
          <p className="get-in-touch__text">{data.text}</p>
          <Button data={buttonData} isPreview={isPreview} />
        </div>
      </div>
    </section>
  )
}

export default GetInTouch
