import React from 'react'
import Link from '~components/Link'

import './index.scss';

const Footer = ({ data, isPreview }) => {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content--left">
          <Link linkClass="footer__logo" to="/" isPreview={isPreview}><img src="/static/huge-logo-black.svg" alt="Huge"/></Link>
          <Link linkClass="footer__privacy-terms" to="/privacy-terms" isPreview={isPreview}>Privacy &amp; Terms</Link>
        </div>
        <div className="footer__content--right">
          <nav className="footer__nav">
            <ul className="footer__nav-list">
              <li className="footer__nav__item">
                <Link to="/contact" isPreview={isPreview}>Contact</Link>
              </li>
              <li className="footer__nav__item">
                <Link to="/careers" isPreview={isPreview}>Careers</Link>
              </li>
              <li className="footer__nav__item">
                <Link to="/huge-ignites" isPreview={isPreview}>Huge Ignites</Link>
              </li>
            </ul>
          </nav>
          <nav className="footer__nav footer__nav--socials">
            <ul className="footer__nav-list">
              <li className="footer__nav__item">
                <a href="https://www.instagram.com/hugedesignsf/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </li>
              <li className="footer__nav__item">
                <a href="https://www.linkedin.com/company/huge-design/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
              </li>
              <li className="footer__nav__item">
                <a href="https://twitter.com/HugeDesignSF" target="_blank" rel="noopener noreferrer">Twitter</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Footer
