import React from 'react'
import Link from '~components/Link'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TransitionLink from 'gatsby-plugin-transition-link'

import './index.scss'

const Button = ({ data, clickHandler, isPreview }) => {
  const buttonClass = classnames(
    'button',
    {
      'button--cta': clickHandler !== undefined
    },
  )

  if (data.tag === 'a') {
    return (
      <a className={buttonClass} href={data.url} target="_blank" rel="noopener noreferrer">
        {data.text}{data.hasArrow ? ' →' : null}
      </a>
    )
  } else if (data.tag === 'link') {
    return (
      <Link linkClass={buttonClass} to={data.url} isPreview={isPreview}>
        {data.text}{data.hasArrow ? ' →' : null}
      </Link>
    )
  } else if (data.tag === 'transitionlink') {
    return (
      <TransitionLink className={buttonClass} to={data.url} exit={data.exit} entry={data.entry} isPreview={isPreview}>
        {data.text}{data.hasArrow ? ' →' : null}
      </TransitionLink>
    )
  } else {
    if (clickHandler) {
      return (
        <button className={buttonClass} id="clickable" onClick={clickHandler}>
          {data.text}{data.hasArrow ? ' →' : null}
        </button>
      )
    }
    return (
      <button className={buttonClass}>
        {data.text}{data.hasArrow ? ' →' : null}
      </button>
    )
  }
}

Button.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    hasArrow: PropTypes.bool,
    exit: PropTypes.object,
    entry: PropTypes.object,
  }),
  clickHandler: PropTypes.func,
}

export default Button
