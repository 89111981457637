import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import Ruler from '~components/Ruler'

import './index.scss'

const OurClients = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-10%",
    threshold: 0
  })

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  const logos = data.clients.map((logo, index) =>
    <div className="our-clients__logo--wrapper" key={`logo-${index}`} style={{ transitionDelay: `${(index + 1) * 0.05}s`}}>
        <img className="our-clients__logo" src={logo.logo} alt={logo.logo_alt} />
    </div>
  )

  return (
    <section className="our-clients" ref={componentRef}>
      <div className="our-clients__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="our-clients__logos">
          {logos}
        </div>
      </div>
    </section>
  )
}

export default OurClients
