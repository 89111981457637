import React, { useState, useRef, useEffect } from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import Ruler from '~components/Ruler'
import Panel from './panel'

import './index.scss'

const Process = ({ data, index, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-30%",
    threshold: 0
  })

  const [isExpanded, setIsExpanded] = useState(false)

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'label',
      color: 'grey'
    }
  }

  const processes = data.processes.map((process, index) =>
    <Panel data={process} index={index} isExpanded={isExpanded}
      panelEventHandler={() => setIsExpanded(!isExpanded)} key={`process-${index}`} isPreview={isPreview}/>
  )

  const wrapperRef = useRef(null)
  const leftControlRef = useRef(null)
  const [leftDisabled, setLeftDisabled] = useState(true)
  const rightControlRef = useRef(null)
  const [rightDisabled, setRightDisabled] = useState(false)


  const handleClick = (event) => {
    if (!wrapperRef.current) {
      return
    }
    const direction = event.target.closest('.process__control').dataset.direction
    const directionMultiplier = direction === 'right' ? 1 : -1
    const panelEl = wrapperRef.current.querySelector('.process__list__item')
    const distance = directionMultiplier * panelEl.getBoundingClientRect().width
    wrapperRef.current.scrollTo({
      left: directionMultiplier === 1 ? Math.min(wrapperRef.current.scrollLeft + distance, wrapperRef.current.scrollWidth) : Math.max(0, wrapperRef.current.scrollLeft + distance),
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const scrollLeft = wrapperRef.current.scrollLeft
        const scrollWidth = wrapperRef.current.scrollWidth

        if (scrollLeft === 0) {
          setLeftDisabled(true)
        } else {
          setLeftDisabled(false)
          leftControlRef.current.classList.add('is-visible')
        }

        if (scrollWidth - scrollLeft - wrapperRef.current.offsetWidth === 0) {
          setRightDisabled(true)
        } else {
          setRightDisabled(false)
        }
      }
    }

    if (wrapperRef.current) {
      const wrapper = wrapperRef.current
      wrapper.addEventListener('scroll', handleScroll)

      return () => {
        wrapper.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <section className="process" ref={componentRef}>
      <div className="process__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="process__content" ref={wrapperRef}>
          <div className="process__list">
            {processes}
          </div>
        </div>
        <div className="process__controls">
          <button className="process__control process__control--left" title="Left" aria-label="Left"
            data-direction="left" onClick={handleClick} ref={leftControlRef} disabled={leftDisabled}
            >
            <svg className="process__control__arrow" viewBox="0 0 56 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="process__control__arrow-line" d="M56 17.5H1v-1H56v1z" fill="#282121"/>
              <path className="process__control__arrow-head" d="M13 5L.5 17l12 12" stroke="#282121" strokeLinejoin="bevel"/>
            </svg>
          </button>
          <button className="process__control process__control--right" title="Right" aria-label="Right"
            data-direction="right" onClick={handleClick} ref={rightControlRef} disabled={rightDisabled}
            >
            <svg className="process__control__arrow" viewBox="0 0 56 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="process__control__arrow-line" d="M0 17.5H55v-1H0v1z" fill="#282121"/>
              <path className="process__control__arrow-head" d="M43 5l12.5 12-12 12" stroke="#282121" strokeLinejoin="bevel"/>
            </svg>
          </button>
        </div>
      </div>
    </section>
  )
}

export default Process
