import { useEffect, useRef, useState } from 'react'

const useIntersectionObserver = (options, isReversible = false) => {
  const componentRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)

    if (componentRef.current) {
      const component = componentRef.current
      observer.observe(component)

      if (isVisible) {
        component.classList.add('is-visible')

        if (!isReversible) {
          observer.unobserve(component)
          observer.disconnect()
        }
      }

      if (isReversible) {
        component.classList.remove('is-visible')
      }

      return () => {
        //@TODO check if cleanup/unobserve is required
        component.classList.remove('is-visible')
        observer.unobserve(component)
        observer.disconnect()
      }
    }
  }, [componentRef, options, isReversible, isVisible])

  return [componentRef, isVisible]
}

export default useIntersectionObserver
