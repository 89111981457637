import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import Ruler from '~components/Ruler'
import Button from '~components/Button'

import './index.scss'

const ProfileCard = ({ profile, index, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  return (
    <div className="our-leadership__profile" key={index} ref={componentRef}>
      { profile.image &&
        <div className="our-leadership__profile__image--wrapper">
          <img className="our-leadership__profile__image" src={profile.image} alt={profile.name} />
        </div>
      }
      <h3 className="our-leadership__profile__name">{profile.name}</h3>
      <p className="our-leadership__profile__title">{profile.job_title}</p>
      <p className="our-leadership__profile__bio">{profile.bio}</p>
      <div className="our-leadership__profile__socials">
        {
          profile.socials.map((social, socialIndex) =>
            <Button
              data={
                {
                  tag: 'a',
                  text: social.social_text,
                  url: social.social_url
                }
              }
              key={`profile-${index}-social-${socialIndex}`}
              isPreview={isPreview}
            />
          )
        }
      </div>
    </div>
  )
}

const OurLeadership = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  return (
    <section className="our-leadership" ref={componentRef}>
      <div className="our-leadership__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <div className="our-leadership__content">
          {/* <div className="our-leadership__partners-image--wrapper"> */}
          {/*   <img className="our-leadership__partners-image" src={data.partners.image} alt={data.partners.image_alt} /> */}
          {/* </div> */}
          {/* <div className="our-leadership__partners"> */}
          {/*   <ProfileCard profile={data.partners.partner_1} index={'partner-1'} isPreview={isPreview} /> */}
          {/*   <ProfileCard profile={data.partners.partner_2} index={'partner-2'} isPreview={isPreview} /> */}
          {/* </div> */}
          <div className="our-leadership__team">
            {
              data.profiles.map((profile, index) =>
                <ProfileCard profile={profile} index={index} key={`profile-${index}`} isPreview={isPreview} />
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurLeadership
