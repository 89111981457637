import React from 'react'

import Ruler from '~components/Ruler'

import './index.scss'
import useMediaQuery from '~hooks/useMediaQuery'
import useIntersectionObserver from '~components/global/IntersectionObserver'

const ImageWrapper = ({image, alt}) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-30%",
    threshold: 0
  })

  return (
    <div className="studio__content__image--wrapper" ref={componentRef}>
      <img className="studio__content__image" src={image} alt={alt} />
    </div>
  )
}
const Studio = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'label',
      color: 'grey'
    }
  }

  const isMobile = useMediaQuery('(max-width: 768px)')

  const studioContent = () => {
    if (isMobile) {
      return (
        <>
          <Ruler data={rulerData} isPreview={isPreview} />
          <div className="studio__content studio__content">
            <p className="studio__content__text">{data.text}</p>
            <ImageWrapper image={data.image_1} alt={data.image_1_alt} />
            <ImageWrapper image={data.image_2} alt={data.image_2_alt} />
            <ImageWrapper image={data.image_3} alt={data.image_3_alt} />
            <ImageWrapper image={data.image_4} alt={data.image_4_alt} />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className="studio__content studio__content--top">
            <div className="studio__content studio__content--top--left">
              <ImageWrapper image={data.image_1} alt={data.image_1_alt} />
            </div>
            <div className="studio__content studio__content--top--right">
              <div className="studio__content__text--wrapper">
                <Ruler data={rulerData} isPreview={isPreview} />
                <p className="studio__content__text">
                  {data.text}
                </p>
              </div>
              <ImageWrapper image={data.image_2} alt={data.image_2_alt} />
            </div>
          </div>
          <div className="studio__content studio__content--bottom">
            <ImageWrapper image={data.image_3} alt={data.image_3_alt} />
            <ImageWrapper image={data.image_4} alt={data.image_4_alt} />
          </div>
        </>
      )
    }
  }


  return (
    <section className="studio" ref={componentRef}>
      <div className="studio__container">
        {studioContent()}
      </div>
    </section>
  )
}

export default Studio
