import React from 'react'

import Layout from '~global/Layout'
import AboutPageWrap from '~pageWraps/AboutPageWrap'

import data from '../content/about.yaml'

const About = () => {
  return (
    <Layout>
      <AboutPageWrap data={data}/>
    </Layout>
  )
}

export default About
