import React, { useEffect, useRef, useState } from 'react'

import Ruler from '~components/Ruler'

import './panel.scss'

const Panel = ({ data, index, panelEventHandler, isExpanded, isPreview }) => {
  const [isDirty, setIsDirty] = useState(false)
  const textRef = useRef()
  const panelRef = useRef()

  useEffect(() => {
    let timeout
    const text = textRef.current
    const transitionEndHandler = (event) => {
      if (event.propertyName === 'max-height') {
        textRef.current.style.maxHeight = ''
      }
    }

    if (text) {
      text.addEventListener('transitionend', transitionEndHandler)
    }

    if (isDirty) {
      clearTimeout(timeout)
    }

    if (isExpanded) {
      if (!isDirty) {
        setIsDirty(true)
      }

      panelRef.current.classList.add('process__list__item--expanded')
      textRef.current.style.maxHeight = '0'

      timeout = setTimeout(() => {
        textRef.current.style.maxHeight = `${textRef.current.children[0].getBoundingClientRect().height + 70}px`
      }, 10);
    }  else {
      if (isDirty) {
        textRef.current.style.maxHeight = `${textRef.current.children[0].getBoundingClientRect().height + 70}px`
        timeout = setTimeout(() => {
          panelRef.current.classList.remove('process__list__item--expanded')
        }, 50);
      }
    }

    return () => {
      if (text) {
        text.removeEventListener('transitionend', transitionEndHandler)
      }

      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isExpanded, isDirty])

  return (
    <div className="process__list__item" key={`process-${index}`} ref={panelRef} style={{ transitionDelay: `${.15 * (index + 1)}s`}}>
      <div className="process__list__item__content">
        <div className="process__list__item__header">
          <p className="process__list__item__header__tag">{data.tag}</p>
          <p className="process__list__item__header__index">{index + 1 < 10 ? `0${index + 1}` : index + 1}</p>
        </div>
        <h2 className="process__list__item__title">{data.title}</h2>
        <p className="process__list__item__description">{data.text}</p>
        <div
          className="process__list__item__text"
          ref={textRef}
        >
          <p className="process__list__item__text__paragraph">{data.additional_text}</p>
        </div>
        <div className="process__list__item__services">
          <p className="process__list__item__services--label">Services</p>
          <ul className="process__list__item__services__list">
          {
            data.services.map((service, index) =>
              <li className="process__list__item__services__list__item" key={`service-${index}`}>{service.service}</li>
            )
          }
          </ul>
        </div>
      </div>
      { isExpanded ?
        <Ruler
          data={
            {
              rightText: {
                tag: 'button',
                text: 'Less'
              }
            }
          }
          buttonClickHandler={panelEventHandler}
          isPreview={isPreview}
        /> :
        <Ruler
          data={
            {
              rightText: {
                tag: 'button',
                text: 'More'
              }
            }
          }
          buttonClickHandler={panelEventHandler}
          isPreview={isPreview}
        />
      }
    </div>
  )
}

export default Panel
