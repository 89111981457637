import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import Ruler from '~components/Ruler'

import './index.scss'

const WeAre = ({ data, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const rulerData = {
    leftText: {
      text: data.title,
      type: 'heading',
      color: 'black'
    }
  }

  return (
    <section className="we-are" ref={componentRef}>
      <div className="we-are__container">
        <Ruler data={rulerData} isPreview={isPreview} />
        <p className="we-are__text">{data.text}</p>
      </div>
    </section>
  )
}

export default WeAre
