import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'

import AboutHero from '~components/pages/about/Hero'
import WeAre from '~components/pages/about/WeAre'
import Process from '~components/pages/about/Process'
import OurLeadership from '~components/pages/about/OurLeadership'
import Studio from '~components/pages/about/Studio'
import Recognition from '~components/pages/about/Recognition'
import OurClients from '~components/pages/about/OurClients'
import GetInTouch from '~components/pages/about/GetInTouch'

const AboutPageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <AboutHero data={data.hero} isPreview={isPreview} />
      <WeAre data={data.we_are} isPreview={isPreview} />
      <Process data={data.process} isPreview={isPreview} />
      <OurLeadership data={data.our_leadership} isPreview={isPreview} />
      <Studio data={data.studio} isPreview={isPreview} />
      {/* <Recognition data={data.recognition} isPreview={isPreview} /> */}
      <OurClients data={data.our_clients} isPreview={isPreview} />
      <GetInTouch data={data.get_in_touch} isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

export default AboutPageWrap
