import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './index.scss'

import Button from '~components/Button'

const Ruler = ({ data, buttonClickHandler, isPreview }) => {
  const rulerClass = classnames(
    'ruler',
    {
      'ruler--title-only': data.leftText && !data.rightText,
      'ruler--full': data.leftText && data.rightText
    }
  )
  const label = (text) => {
    return (
      <p className={`ruler__label ruler__label--${text.color}`}>{text.text}</p>
    )
  }

  const leftText = (leftText) => {
    if (leftText.type === 'heading') {
      return (
        <h2 className="ruler__title">{leftText.text}</h2>
      )
    } else if (leftText.type === 'label') {
      return (
        <>
          {label(leftText)}
        </>
      )
    }
  }

  const rightText = (rightText) => {
    if (rightText.tag === 'button') {
      if (buttonClickHandler) {
        return (
          <Button data={data.rightText} clickHandler={buttonClickHandler} isPreview={isPreview} />
        )
      } else {
        return (
          <Button data={data.rightText} isPreview={isPreview} />
        )
      }
    } else {
      return (
        <Button data={data.rightText} isPreview={isPreview} />
      )
    }
  }

  return (
    <>
      <div className={rulerClass}>
        { data.leftText ? leftText(data.leftText) : null }
        { data.rightText ? rightText(data.rightText) : null }
      </div>
    </>
  )
}

Ruler.propTypes = {
  data: PropTypes.shape({
    leftText: PropTypes.object,
    rightText: PropTypes.object
  }),
}

export default Ruler
