import React from 'react'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import './index.scss'

const AboutHero = ({ data }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  return (
    <section className="about-hero" ref={componentRef}>
      <div className="about-hero__container">
        <h1 className="about-hero__title">{data.title}</h1>
        <div className="about-hero__image--wrapper">
          <img className="about-hero__image" src={data.image} alt={data.image_alt} />
        </div>
      </div>
    </section>
  )
}

export default AboutHero
